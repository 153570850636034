<template>
  <Transition appear v-if="data">
    <section
      class="section-objectPageNews"
      style="background-color: rgba(182, 209, 222, 0.25); padding: 2rem"
    >
      <v-row>
        <v-col cols="2">
          <v-img
            v-if="data.Image"
            :src="$store.state.url + data.Image.fullpath"
            height="110"
            contain
            :alt="
              data.Image.metadata
                ? data.Image.metadata[0].data
                : data.Image.filename
            "
          />
        </v-col>

        <v-col cols="10">
          <h4 v-if="data.Title">{{ data.Title }}</h4>

          <p v-if="data.TeaserText" v-html="data.TeaserText" class="small"></p>

          <div v-if="data.video">
            <a
              :href="$store.state.url + data.video.data.fullpath"
              target="_blank"
            >
              {{ data.video.title }}
            </a>
          </div>

          <div v-if="data.assetsdata">
            <template v-for="(asset, i) in data.assetsdata">
              <a
                :key="i"
                :href="$store.state.url + asset.element.fullpath"
                target="_blank"
                >{{ asset.element.filename }}</a
              >
            </template>
          </div>

          <a :href="'/news-single?id=' + data.id"> Mehr erfahren </a>
        </v-col>
      </v-row>
    </section>
  </Transition>
</template>

<script>
import componentData from "@/mixins/data/component";

export default {
  name: "object_PageNews",
  mixins: [componentData],
};
</script>
