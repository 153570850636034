<template>
  <Transition appear v-if="data">
    <section class="section-pageContent" :class="data.Layout">
      <v-row
        v-if="
          !data.Text && !data.Headline && data.Image1 && data.Image1.fullpath
        "
      >
        <v-col cols="12">
          <v-img
            contain
            height="auto"
            v-if="data.Image1 && data.Image1.fullpath"
            :src="$store.state.url + data.Image1.fullpath"
            :alt="
              data.Image1.metadata
                ? data.Image1.metadata[0].data
                : data.Image1.filename
            "
          />
        </v-col>
      </v-row>

      <v-row v-if="data.Layout == 'Simple'">
        <v-col cols="12">
          <h3 style="margin: 0">{{ data.Headline }}</h3>
        </v-col>

        <template v-if="data.Image1 && data.Image1.fullpath && !data.Image2">
          <v-col cols="12" lg="6">
            <v-img
              cover
              :src="$store.state.url + data.Image1.fullpath"
              :alt="
                data.Image1.metadata
                  ? data.Image1.metadata[0].data
                  : data.Image1.filename
              "
            />
          </v-col>
        </template>

        <template
          v-if="
            data.Image1 &&
            data.Image1.fullpath &&
            data.Image2 &&
            data.Image2.fullpath &&
            !data.Image3
          "
        >
          <v-col cols="12" md="6" class="order-last order-md-first">
            <v-img
              cover
              :src="$store.state.url + data.Image1.fullpath"
              :alt="
                data.Image1.metadata
                  ? data.Image1.metadata[0].data
                  : data.Image1.filename
              "
            />
          </v-col>

          <v-col cols="12" md="6" class="order-last order-md-first">
            <v-img
              cover
              :src="$store.state.url + data.Image2.fullpath"
              :alt="
                data.Image2.metadata
                  ? data.Image2.metadata[0].data
                  : data.Image2.filename
              "
            />
          </v-col>
        </template>

        <template
          v-if="
            data.Image1 &&
            data.Image1.fullpath &&
            data.Image2 &&
            data.Image2.fullpath &&
            data.Image3 &&
            data.Image3.fullpath
          "
        >
          <v-col cols="12" md="4">
            <v-img
              cover
              :src="$store.state.url + data.Image1.fullpath"
              :alt="
                data.Image1.metadata
                  ? data.Image1.metadata[0].data
                  : data.Image1.filename
              "
            />
          </v-col>

          <v-col cols="12" md="4">
            <v-img
              cover
              :src="$store.state.url + data.Image2.fullpath"
              :alt="
                data.Image2.metadata
                  ? data.Image2.metadata[0].data
                  : data.Image2.filename
              "
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-img
              cover
              v-if="data.Image3 && data.Image3.fullpath"
              :src="$store.state.url + data.Image3.fullpath"
              :alt="
                data.Image3.metadata
                  ? data.Image3.metadata[0].data
                  : data.Image3.filename
              "
            />
          </v-col>
        </template>

        <v-col cols="12">
          <span v-html="data.Text"></span>
        </v-col>
      </v-row>

      <v-row v-else-if="data.Layout == 'Chess'">
        <v-col cols="12">
          <h3 style="margin: 0">{{ data.Headline }}</h3>
        </v-col>
        <v-col cols="0" md="6">
          <v-img
            cover
            v-if="data.Image1 && data.Image1.fullpath"
            :src="$store.state.url + data.Image1.fullpath"
            :alt="
              data.Image1.metadata
                ? data.Image1.metadata[0].data
                : data.Image1.filename
            "
          />
          <v-img
            cover
            v-if="data.Image2 && data.Image2.fullpath"
            :src="$store.state.url + data.Image2.fullpath"
            class="d-none d-md-block"
            :alt="
              data.Image2.metadata
                ? data.Image2.metadata[0].data
                : data.Image2.filename
            "
          />
          <v-img
            cover
            v-if="data.Image3 && data.Image3.fullpath"
            :src="$store.state.url + data.Image3.fullpath"
            class="d-none d-md-block"
            :alt="
              data.Image3.metadata
                ? data.Image3.metadata[0].data
                : data.Image3.filename
            "
          />
        </v-col>
        <v-col cols="12" md="6">
          <span v-html="data.Text"></span>
        </v-col>
        <v-col>
          <v-img
            cover
            v-if="data.Image2 && data.Image2.fullpath"
            :src="$store.state.url + data.Image2.fullpath"
            class="d-block d-md-none"
            :alt="
              data.Image2.metadata
                ? data.Image2.metadata[0].data
                : data.Image2.filename
            "
          />
          <v-img
            cover
            v-if="data.Image3 && data.Image3.fullpath"
            :src="$store.state.url + data.Image3.fullpath"
            class="d-block d-md-none"
            :alt="
              data.Image3.metadata
                ? data.Image3.metadata[0].data
                : data.Image3.filename
            "
          />
        </v-col>
      </v-row>

      <v-row v-else-if="data.Layout == 'SmallImage'">
        <v-col cols="12">
          <h3 style="margin: 0">{{ data.Headline }}</h3>
        </v-col>
        <v-col cols="12" md="4">
          <v-img
            cover
            v-if="data.Image1 && data.Image1.fullpath"
            :src="$store.state.url + data.Image1.fullpath"
            :alt="
              data.Image1.metadata
                ? data.Image1.metadata[0].data
                : data.Image1.filename
            "
          />
        </v-col>
        <v-col cols="12" md="8">
          <span v-html="data.Text"></span>
        </v-col>
      </v-row>
    </section>
  </Transition>
</template>

<script>
import componentData from "@/mixins/data/component";

export default {
  name: "object_PageContent",
  mixins: [componentData],
};
</script>

<style lang="scss" scoped>
.section-pageContent {
  .v-image {
    margin-bottom: 1rem;
    height: 240px;
  }
}
</style>
