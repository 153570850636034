var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"section-objectListconfig"},[(_vm.data)?_c('Transition',{attrs:{"appear":""}},[(_vm.data.SliderConfig && _vm.items)?_c('div',[(_vm.data.SliderConfig)?_c('Slider',{attrs:{"data":_vm.items,"config":_vm.data.SliderConfig,"template":_vm.data.Template}}):_vm._e()],1):(
        _vm.items.length > 0 &&
        (_vm.data.widthBase ||
          _vm.data.widthXS ||
          _vm.data.widthSM ||
          _vm.data.widthMD ||
          _vm.data.widthLG ||
          _vm.data.widthXL)
      )?_c('v-row',_vm._l((_vm.items),function(item,index){return _c('v-col',{key:index,staticClass:"col",class:[
          _vm.data.widthBase ? 'col-' + _vm.data.widthBase : '',
          _vm.data.widthXS ? 'col-xs-' + _vm.data.widthXS : '',
          _vm.data.widthSM ? 'col-sm-' + _vm.data.widthSM : '',
          _vm.data.widthMD ? 'col-md-' + _vm.data.widthMD : '',
          _vm.data.widthLG ? 'col-lg-' + _vm.data.widthLG : '',
          _vm.data.widthXL ? 'col-xl-' + _vm.data.widthXL : '' ]},[_c(_vm.data.Template,{tag:"component",attrs:{"data":item.node,"index":index}})],1)}),1):(
        (_vm.data.widthBase ||
          _vm.data.widthXS ||
          _vm.data.widthSM ||
          _vm.data.widthMD ||
          _vm.data.widthLG ||
          _vm.data.widthXL) &&
        _vm.data.Items &&
        !_vm.data.Listing
      )?_c('v-row',_vm._l((_vm.data.Items),function(item,index){return _c('v-col',{key:index,staticClass:"col",class:[
          _vm.data.widthBase ? 'col-' + _vm.data.widthBase : '',
          _vm.data.widthXS ? 'col-xs-' + _vm.data.widthXS : '',
          _vm.data.widthSM ? 'col-sm-' + _vm.data.widthSM : '',
          _vm.data.widthMD ? 'col-md-' + _vm.data.widthMD : '',
          _vm.data.widthLG ? 'col-lg-' + _vm.data.widthLG : '',
          _vm.data.widthXL ? 'col-xl-' + _vm.data.widthXL : '' ]},[_c(_vm.data.Template,{tag:"component",attrs:{"data":item,"index":index}})],1)}),1):(_vm.data && _vm.items.length > 0)?_c('div',{staticClass:"container bg-secondary pa-0"},[_vm._l((_vm.items),function(item,index){return [_c(_vm.data.Template,{key:index,tag:"component",attrs:{"data":item.node,"index":index}})]})],2):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }