<template>
  <Transition appear v-if="data">
    <section class="section-page" v-if="data">
      <section
        class="section-objectPageNews"
        style="background-color: rgba(182, 209, 222, 0.25); padding: 2rem"
      >
        <v-row>
          <v-col cols="6" md="2">
            <v-img
              v-if="data.Image"
              :src="$store.state.url + data.Image.fullpath"
              height="110"
              contain
              :alt="
                data.Image.metadata
                  ? data.Image.metadata[0].data
                  : data.Image.filename
              "
            />
            <v-img
              v-if="data.teaserImage"
              :src="$store.state.url + data.teaserImage.fullpath"
              height="110"
              cover
              :alt="
                data.teaserImage.metadata
                  ? data.teaserImage.metadata[0].data
                  : data.teaserImage.filename
              "
            />
          </v-col>

          <v-col cols="6" md="10">
            <h4 v-if="data.Title">{{ data.Title }}</h4>
            <p v-if="data.Content[0].element.Headline">
              {{ data.Content[0].element.Headline }}
            </p>
            <a :href="'/' + data.Url"> Mehr erfahren </a>
          </v-col>
        </v-row>
      </section>
    </section>
  </Transition>
</template>

<script>
import componentData from "@/mixins/data/component";

export default {
  name: "object_Page",
  mixins: [componentData],
};
</script>

<style lang="scss" scoped></style>
