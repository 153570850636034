<template>
  <Transition appear v-if="data">
    <section class="section-objectEmployees">
      <v-row class="px-6">
        <v-col cols="12" md="6" v-if="data.image && data.image.fullpath">
          <v-img
            :src="$store.state.url + data.image.fullpath"
            :alt="
              data.image.metadata
                ? data.image.metadata[0].data
                : data.image.filename
            "
          />
        </v-col>

        <v-col cols="12" :md="data.image ? '6' : '12'">
          <h5 v-if="data.title">{{ data.title }}</h5>
          <h2 v-if="data.firstname">
            {{ data.firstname }} {{ data.lastname }}
          </h2>
          <h3>{{ data.position }}</h3>
          <br />
          <p v-if="data.telephone">Telefon: {{ data.telephone }}</p>
          <p v-if="data.email">E-Mail: {{ data.email }}</p>
          <p v-if="data.mobile">Mobil: {{ data.mobile }}</p>
        </v-col>
      </v-row>
    </section>
  </Transition>
</template>

<script>
import componentData from "@/mixins/data/component";

export default {
  name: "object_Employees",
  mixins: [componentData],
};
</script>

<style lang="scss" scoped>
section.section-objectEmployees {
  margin: 4rem 0;
  h5,
  h2,
  h3 {
    margin-bottom: 0;
  }
}
</style>
