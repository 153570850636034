<template>
  <div id="app">
    <v-app style="background-color: transparent">
      <TheHeader />
      <v-main>
        <router-view></router-view>
        <TheFooter />
      </v-main>
    </v-app>
  </div>
</template>

<script>
import TheHeader from "./components/main/theHeader";
import TheFooter from "./components/main/theFooter";
import loadMetadata from "@/mixins/query/loadMetadata";
import loadNavi from "@/mixins/query/laodNavi";
import loadFooter from "@/mixins/query/loadFooter";

export default {
  name: "App",
  components: { TheHeader, TheFooter },
  mixins: [loadMetadata, loadNavi, loadFooter],
};
</script>

<style lang="scss">
@import "./css/style";
</style>
