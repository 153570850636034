import { render, staticRenderFns } from "./SlickCarousel.vue?vue&type=template&id=e63b7362&scoped=true&"
import script from "./SlickCarousel.vue?vue&type=script&lang=js&"
export * from "./SlickCarousel.vue?vue&type=script&lang=js&"
import style0 from "./SlickCarousel.vue?vue&type=style&index=0&id=e63b7362&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e63b7362",
  null
  
)

export default component.exports