<template>
  <section
    class="template-newsDefault"
    style="
      margin-bottom: 2.5rem;
      padding-bottom: 4rem;
      border-bottom: 0.25rem solid #b1cfdd;
      position: relative;
      height: 100%;
    "
  >
    <v-img
      v-if="data.Image"
      :src="$store.state.url + data.Image.fullpath"
      height="240"
      class="mb-4"
    />

    <!--    <div-->
    <!--      v-else-->
    <!--      class="mb-4"-->
    <!--      style="-->
    <!--        background-color: white;-->
    <!--        height: 240px;-->
    <!--        display: flex;-->
    <!--        justify-content: center;-->
    <!--        align-items: center;-->
    <!--      "-->
    <!--    >-->
    <!--      <span style="font-weight: bold"> NEWS </span>-->
    <!--    </div>-->

    <h2 v-if="data.Title" v-html="data.Title" />

    <p v-if="data.TeaserText" v-html="data.TeaserText"></p>

    <div v-if="data.video">
      <a :href="$store.state.url + data.video.data.fullpath" target="_blank">{{
        data.video.title
      }}</a>
    </div>

    <div v-if="data.assetsdata">
      <template v-for="(asset, i) in data.assetsdata">
        <a
          :key="i"
          :href="$store.state.url + asset.element.fullpath"
          target="_blank"
          >{{ asset.element.filename }}</a
        >
      </template>
    </div>

    <v-btn
      elevation="0"
      color="white"
      tile
      :href="'/news-single?id=' + data.id"
      style="position: absolute; bottom: 2rem"
    >
      Mehr erfahren
    </v-btn>
  </section>
</template>

<script>
import componentData from "@/mixins/data/component";

export default {
  name: "NewsDefault",
  mixins: [componentData],
};
</script>
