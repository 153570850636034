import Vue from "vue";
import VueRouter from "vue-router";
import Page from "@/components/pages/page";
Vue.use(VueRouter);

const routes = [
  {
    name: "cms",
    path: "/:url(.*)",
    component: Page,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  // hack to allow for forward slashes in path ids
  if (to.fullPath.includes("%2F")) {
    next(to.fullPath.replace("%2F", "/"));
  }
  next();
});

export default router;
