<template>
  <transition-group appear v-if="condition">
    <v-row v-for="e in 3" :style="e == 1 ? 'margin-top: 180px' : ''" :key="e">
      <v-col cols="12" md="6">
        <v-skeleton-loader class="mx-auto" type="image"></v-skeleton-loader>
      </v-col>
      <v-col cols="12" md="6">
        <v-skeleton-loader class="mx-auto mb-4" type="heading">
        </v-skeleton-loader>
        <v-skeleton-loader
          class="mx-auto mb-4"
          type="text@2"
        ></v-skeleton-loader>
        <v-skeleton-loader
          class="mx-auto mb-4"
          type="text@2"
        ></v-skeleton-loader>
        <v-skeleton-loader class="mx-auto" type="button"></v-skeleton-loader>
      </v-col>
    </v-row>
  </transition-group>
</template>

<script>
export default {
  name: "Skeleton",
  props: ["condition"],
};
</script>
