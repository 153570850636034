<template>
  <section class="section-newsPicker">
    <transition appear v-if="news.id">
      <v-row>
        <v-col cols="12">
          <h2 style="margin: 0">{{ news.Title }}</h2>
        </v-col>

        <v-col cols="12" lg="6" v-if="news.Image && news.Image.fullpath">
          <v-img
            :src="$store.state.url + news.Image.fullpath"
            max-height="50vh"
            min-height="240px"
            :alt="getMetadata(news.Image, 'alt')"
            :title="getMetadata(news.Image, 'title')"
            :copyright="getMetadata(news.Image, 'copyright')"
          />
        </v-col>

        <v-col :cols="12" :lg="news.Image ? 6 : 12">
          <p v-html="news.Text"></p>
          <div v-if="news.assetsdata">
            <template v-for="(asset, i) in news.assetsdata">
              <a
                :key="i"
                :href="$store.state.url + asset.element.fullpath"
                target="_blank"
                >{{ asset.element.filename }}</a
              >
            </template>
          </div>
        </v-col>
      </v-row>

      <video
        width="100%"
        controls
        v-if="news.video && news.video.data"
        style="margin-top: 2rem"
      >
        <source
          :src="$store.state.url + news.video.data.fullpath"
          type="video/mp4"
        />
        Your browser does not support HTML video.
      </video>
    </transition>

    <template v-if="news === ''">
      <h2>News nicht gefunden</h2>
    </template>
  </section>
</template>

<script>
import News from "@/graphql/queries/news.graphql";
import componentData from "@/mixins/data/component";

export default {
  mixins: [componentData],
  name: "object_PageNewsPicker",
  data() {
    return {
      news: "empty",
    };
  },
  methods: {
    loadProducts() {
      this.$apollo
        .query({
          query: News,
          variables: {
            ids: this.$route.query.id,
            lang: "de",
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          this.news =
            response.data.content.edges[0].node ||
            response.data.content.edges[0] ||
            response.data.content.edges ||
            "";
        });
    },
    getMetadata(image, wanted) {
      if (image.metadata) {
        for (let i = 0; i < Object.keys(image.metadata).length; i++) {
          if (image.metadata[i].name === wanted) {
            return image.metadata[i].data;
          }
        }
      }
    },
  },
  created() {
    this.loadProducts();
  },
};
</script>
