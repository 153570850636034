export default {
  methods: {
    hoverParent() {
      const parents = document.querySelectorAll(".hs-has-sub");

      parents.forEach((e) => {
        e.addEventListener("mouseover", function () {
          if (window.innerWidth > 991) {
            e.classList.add("hs-sub-menu-opened");
            e.querySelector(".hs-sub-menu").style.display = "block";
          }
        });
        e.addEventListener("mouseout", function () {
          if (window.innerWidth > 991) {
            e.classList.remove("hs-sub-menu-opened");
            e.querySelector(".hs-sub-menu").style.display = "none";
          }
        });
      });
    },

    clickParent() {
      const parents = document.querySelectorAll(
        ".hs-has-sub .mobile-opener-parent"
      );
      parents.forEach((e) => {
        let sibling = e.nextSibling;
        e.addEventListener("click", function () {
          if (window.innerWidth <= 991) {
            sibling.classList.toggle("hs-sub-menu-opened");
            e.firstChild.classList.toggle("rotated");

            if (sibling.style.display == "none") {
              sibling.style.display = "block";
            } else {
              sibling.style.display = "none";
            }
          }
        });
      });
    },
    hoverChild() {
      const childs = document.querySelectorAll(".hs-has-sub-menu");
      childs.forEach((e) => {
        e.addEventListener("mouseover", function () {
          if (window.innerWidth > 991) {
            e.classList.add("hs-sub-menu-opened");
            e.querySelector(".hs-sub-menu").style.display = "block";
          }
        });
        e.addEventListener("mouseout", function () {
          if (window.innerWidth > 991) {
            e.classList.remove("hs-sub-menu-opened");
            e.querySelector(".hs-sub-menu").style.display = "none";
          }
        });
      });
    },
    clickChild() {
      const childs = document.querySelectorAll(
        ".hs-has-sub-menu .mobile-opener-child"
      );
      childs.forEach((e) => {
        e.addEventListener("click", function () {
          if (window.innerWidth <= 991) {
            let sibling = e.nextSibling;
            sibling.classList.toggle("hs-sub-menu-opened");
            e.firstChild.classList.toggle("rotated");

            if (sibling.style.display == "none") {
              sibling.style.display = "block";
            } else {
              sibling.style.display = "none";
            }
          }
        });
      });
    },
    toggleMobileDropdown() {
      const toggler = document.querySelector(".navbar-toggler");
      toggler.addEventListener("click", function () {
        if (
          this.querySelector(".navbar-toggler-default").style.display == "none"
        ) {
          this.querySelector(".navbar-toggler-default").style.display = "flex";
          this.querySelector(".navbar-toggler-toggled").style.display = "none";
          document.querySelector(".navbar-collapse").classList.toggle("show");
        } else {
          this.querySelector(".navbar-toggler-default").style.display = "none";
          this.querySelector(".navbar-toggler-toggled").style.display = "flex";
          document.querySelector(".navbar-collapse").classList.toggle("show");
        }
      });
    },
    menuMethodBundle() {
      this.hoverParent();
      this.hoverChild();
      this.clickParent();
      this.clickChild();
    },
  },

  mounted() {
    this.menuMethodBundle();
    this.toggleMobileDropdown();
  },
  updated() {
    this.menuMethodBundle();
  },
  watch: {
    $route() {
      document.querySelector(".navbar-toggler").click();
      document.querySelectorAll(".hs-sub-menu").forEach((e) => {
        e.style.display = "none";
      });
      document
        .querySelectorAll(".rotated")
        .forEach((e) => [e.classList.remove("rotated")]);
    },
  },
};
