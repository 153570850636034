export default {
  methods: {
    detectScroll() {
      document.addEventListener("scroll", function () {
        const header = document.querySelector("header");
        if (window.scrollY > 0) {
          header.classList.add("navbar-scrolled");
        } else {
          header.classList.remove("navbar-scrolled");
        }
      });
    },
  },
  mounted() {
    this.detectScroll();
  },
};
