import Page from "@/graphql/queries/page.graphql";

export default {
  name: "loadPage",

  data() {
    return {
      query: {
        started: false,
        loading: false,
        ended: false,
        failed: false,
        succeed: false,
        result: [],
      },
    };
  },
  head: {
    title: function () {
      return {
        inner: "SLI GmbH | " + this.pageTitle,
        separator: " ",
      };
    },
  },
  computed: {
    pageTitle() {
      if (this.query.result.Title == undefined) {
        return "loading...";
      }
      return this.query.result.Title;
    },
    queryListing() {
      const filter = '{"Url": {"$like" :"%' + this.$route.params.url + '%"}}';

      return {
        query: Page,
        variables: {
          filter: filter,
          lang: this.$store.state.lang,
        },
        fetchPolicy: "no-cache",
      };
    },
  },
  methods: {
    load() {
      this.query.started = true;
      this.query.loading = true;
      this.query.ended = false;
      this.query.succeed = false;

      this.$apollo
        .query(this.queryListing)
        .then((response) => {
          if (response.data.content.edges[0] || response.data.getPage) {
            this.query.result =
              response.data.content.edges[0].node || undefined;
            this.query.loading = false;
            this.query.ended = true;
            this.query.succeed = true;

            this.$emit("updateHead");
          }
        })
        .catch((e) => {
          this.query.loading = false;
          this.query.ended = true;
          this.query.failed = true;
          console.log(e);
        });
    },
  },
  created() {
    this.load();
  },
  watch: {
    $route() {
      this.dataLoaded = false;
      this.query.result = [];
      this.load();
    },
  },
};
