export default {
  mounted() {
    this.checkVisible();
  },
  methods: {
    checkVisible() {
      if (this.dependsOn && this.$store.state.form[this.dependsOn] == false) {
        this.visible = false;
      } else {
        this.visible = true;
      }
    },
  },
  watch: {
    name: function (oldVal, newVal) {
      if (oldVal != newVal) {
        this.checkVisible();
      }
    },
  },
};
