import slider from "@/graphql/queries/slider.graphql";
import news from "@/graphql/queries/news.graphql";
import employees from "@/graphql/queries/employees.graphql";

export default {
  methods: {
    load() {
      const vari = {};
      this.setLimit(vari);
      this.setFilter(vari);

      vari.lang = this.$store.state.lang;

      let definedQuery = "";
      if (this.data.Listing == "Slider") definedQuery = slider;
      if (this.data.Listing == "News") definedQuery = news;
      if (this.data.Listing == "Employees") definedQuery = employees;

      if (this.data.Listing) {
        this.$apollo
          .query({
            query: definedQuery,
            variables: vari,
            fetchPolicy: "no-cache",
          })
          .then((response) => {
            this.items = response.data.content.edges;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    setLimit(vari) {
      if (this.data.MaxEntries) {
        console.log("max existiert");
        vari.first = parseInt(this.data.MaxEntries);
      } else {
        vari.first = 0;
      }
      return vari;
    },
    addChoice(filter) {
      const parts = filter.Choices.split(",");
      if (Array.isArray(parts)) {
        this.choices.push(parts);
      }
    },
    addQuery(query, filter) {
      if (this.selected[filter.Attribute]) {
        query.push(
          '{"' +
            filter.Attribute +
            '": {"$like" :"%' +
            this.selected[filter.Attribute] +
            '%"}}'
        );
      }
      return query;
    },
    setFilter(vari) {
      const query = [];
      if (this.data.EnableFilter) {
        if (Array.isArray(this.data.Filter)) {
          this.data.Filter.forEach((element) => {
            vari[element.Attribute] = this.isNumber(element.Choices);
          });
        }
      }

      if (this.data.SearchFields) {
        const fields = this.data.SearchFields.split(",");
        if (Array.isArray(fields)) {
          var searchFields = [];
          fields.forEach((element) =>
            searchFields.push(
              '{"' + element + '": {"$like" :"%' + this.search + '%"}}'
            )
          );
          var searchTerm = searchFields.join(",");
        }
      }

      if (this.search && searchTerm) {
        query.push('{"$or": [' + searchTerm + "]}");
      }
      const q = query.join(",");

      if (q) {
        vari.filter = '{"$and": [' + q + "]}";
      }

      if (this.data.Items) {
        if (Array.isArray(this.data.Items)) {
          var ids = [];
          this.data.Items.forEach(function (item) {
            ids.push(item.id);
          });
          ids = ids.join(",");
          vari.ids = ids;
        }
      }

      return vari;
    },
    isNumber(str) {
      const num = Number(str);

      if (Number.isInteger(num)) {
        return num;
      }
      return str;
    },
  },
  beforeMount() {
    // console.log(this.$route.meta.Listing);
    // this.Listing = this.$route.meta.Listing;
  },
  mounted() {
    this.load();
  },
  watch: {
    $route() {
      this.load();
    },
  },
};
