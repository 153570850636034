<template>
  <Transition appear v-if="data">
    <section class="section-pageHeader">
      <v-img
        v-if="data.Image"
        :src="$store.state.url + data.Image.fullpath"
        style="height: 180px"
        :alt="
          data.Image.metadata
            ? data.Image.metadata[0].data
            : data.Image.filename
        "
      />
    </section>
  </Transition>
</template>

<script>
import componentData from "@/mixins/data/component";

export default {
  name: "object_PageHeader",
  mixins: [componentData],
};
</script>

<style lang="scss" scoped>
.section-pageheader {
  width: calc(100% + 24px);
  transform: translateX(-12px);
}
</style>
