<template>
  <section class="section-footer">
    <footer class="position-relative">
      <div class="container py-5 content-space-b-sm-1">
        <div class="row justify-content-between align-items-center">
          <div class="col-sm mb-5 mb-sm-0">
            <h5 class="mb-0" style="float: left">{{ data.text }}</h5>
            <ul class="list-inline mb-0 pl-0" style="float: right">
              <li
                class="list-inline-item"
                v-for="(link, key) in data.links"
                :key="key"
              >
                <template v-if="key > 0" style="margin-left: 0.5rem">
                  |
                </template>
                <h5 class="mb-0" style="display: inline-block">
                  <router-link
                    class="link text-black"
                    :to="'/' + link.element.Url"
                  >
                    {{ link.element.Title }}</router-link
                  >
                </h5>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  </section>
</template>

<script>
export default {
  name: "TheFooter",
  computed: {
    data() {
      return this.$store.state.footer;
    },
  },
};
</script>
