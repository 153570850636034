<template>
  <section class="section-objectListconfig">
    <Transition appear v-if="data">
      <div v-if="data.SliderConfig && items">
        <Slider
          v-if="data.SliderConfig"
          :data="items"
          :config="data.SliderConfig"
          :template="data.Template"
        />
      </div>

      <v-row
        v-else-if="
          items.length > 0 &&
          (data.widthBase ||
            data.widthXS ||
            data.widthSM ||
            data.widthMD ||
            data.widthLG ||
            data.widthXL)
        "
      >
        <v-col
          :class="[
            data.widthBase ? 'col-' + data.widthBase : '',
            data.widthXS ? 'col-xs-' + data.widthXS : '',
            data.widthSM ? 'col-sm-' + data.widthSM : '',
            data.widthMD ? 'col-md-' + data.widthMD : '',
            data.widthLG ? 'col-lg-' + data.widthLG : '',
            data.widthXL ? 'col-xl-' + data.widthXL : '',
          ]"
          v-for="(item, index) in items"
          :key="index"
          class="col"
        >
          <component :data="item.node" :is="data.Template" :index="index" />
        </v-col>
      </v-row>
      <v-row
        v-else-if="
          (data.widthBase ||
            data.widthXS ||
            data.widthSM ||
            data.widthMD ||
            data.widthLG ||
            data.widthXL) &&
          data.Items &&
          !data.Listing
        "
      >
        <v-col
          v-for="(item, index) in data.Items"
          :class="[
            data.widthBase ? 'col-' + data.widthBase : '',
            data.widthXS ? 'col-xs-' + data.widthXS : '',
            data.widthSM ? 'col-sm-' + data.widthSM : '',
            data.widthMD ? 'col-md-' + data.widthMD : '',
            data.widthLG ? 'col-lg-' + data.widthLG : '',
            data.widthXL ? 'col-xl-' + data.widthXL : '',
          ]"
          :key="index"
          class="col"
        >
          <component :data="item" :is="data.Template" :index="index" />
        </v-col>
      </v-row>

      <div
        v-else-if="data && items.length > 0"
        class="container bg-secondary pa-0"
      >
        <template v-for="(item, index) in items">
          <component
            :data="item.node"
            :is="data.Template"
            :key="index"
            :index="index"
          />
        </template>
      </div>
    </Transition>
  </section>
</template>

<script>
import componentData from "@/mixins/data/component";
import loadListConfig from "@/mixins/query/loadListConfig";
import Slider from "@/components/Wrapper/SlickCarousel";

import EmployeesDefault from "@/components/Templates/employees/default";
import NewsDefault from "@/components/Templates/news/default";
import homelink from "@/components/Templates/homelinks/homelink";

export default {
  name: "object_ListConfig",
  components: {
    Slider,
    EmployeesDefault,
    NewsDefault,
    homelink,
  },
  mixins: [loadListConfig, componentData],
  data() {
    return {
      items: "",
    };
  },
};
</script>
