var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data)?_c('Transition',{attrs:{"appear":""}},[_c('section',{staticClass:"section-pageContent",class:_vm.data.Layout},[(
        !_vm.data.Text && !_vm.data.Headline && _vm.data.Image1 && _vm.data.Image1.fullpath
      )?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.data.Image1 && _vm.data.Image1.fullpath)?_c('v-img',{attrs:{"contain":"","height":"auto","src":_vm.$store.state.url + _vm.data.Image1.fullpath,"alt":_vm.data.Image1.metadata
              ? _vm.data.Image1.metadata[0].data
              : _vm.data.Image1.filename}}):_vm._e()],1)],1):_vm._e(),(_vm.data.Layout == 'Simple')?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticStyle:{"margin":"0"}},[_vm._v(_vm._s(_vm.data.Headline))])]),(_vm.data.Image1 && _vm.data.Image1.fullpath && !_vm.data.Image2)?[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-img',{attrs:{"cover":"","src":_vm.$store.state.url + _vm.data.Image1.fullpath,"alt":_vm.data.Image1.metadata
                ? _vm.data.Image1.metadata[0].data
                : _vm.data.Image1.filename}})],1)]:_vm._e(),(
          _vm.data.Image1 &&
          _vm.data.Image1.fullpath &&
          _vm.data.Image2 &&
          _vm.data.Image2.fullpath &&
          !_vm.data.Image3
        )?[_c('v-col',{staticClass:"order-last order-md-first",attrs:{"cols":"12","md":"6"}},[_c('v-img',{attrs:{"cover":"","src":_vm.$store.state.url + _vm.data.Image1.fullpath,"alt":_vm.data.Image1.metadata
                ? _vm.data.Image1.metadata[0].data
                : _vm.data.Image1.filename}})],1),_c('v-col',{staticClass:"order-last order-md-first",attrs:{"cols":"12","md":"6"}},[_c('v-img',{attrs:{"cover":"","src":_vm.$store.state.url + _vm.data.Image2.fullpath,"alt":_vm.data.Image2.metadata
                ? _vm.data.Image2.metadata[0].data
                : _vm.data.Image2.filename}})],1)]:_vm._e(),(
          _vm.data.Image1 &&
          _vm.data.Image1.fullpath &&
          _vm.data.Image2 &&
          _vm.data.Image2.fullpath &&
          _vm.data.Image3 &&
          _vm.data.Image3.fullpath
        )?[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-img',{attrs:{"cover":"","src":_vm.$store.state.url + _vm.data.Image1.fullpath,"alt":_vm.data.Image1.metadata
                ? _vm.data.Image1.metadata[0].data
                : _vm.data.Image1.filename}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-img',{attrs:{"cover":"","src":_vm.$store.state.url + _vm.data.Image2.fullpath,"alt":_vm.data.Image2.metadata
                ? _vm.data.Image2.metadata[0].data
                : _vm.data.Image2.filename}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[(_vm.data.Image3 && _vm.data.Image3.fullpath)?_c('v-img',{attrs:{"cover":"","src":_vm.$store.state.url + _vm.data.Image3.fullpath,"alt":_vm.data.Image3.metadata
                ? _vm.data.Image3.metadata[0].data
                : _vm.data.Image3.filename}}):_vm._e()],1)]:_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.data.Text)}})])],2):(_vm.data.Layout == 'Chess')?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticStyle:{"margin":"0"}},[_vm._v(_vm._s(_vm.data.Headline))])]),_c('v-col',{attrs:{"cols":"0","md":"6"}},[(_vm.data.Image1 && _vm.data.Image1.fullpath)?_c('v-img',{attrs:{"cover":"","src":_vm.$store.state.url + _vm.data.Image1.fullpath,"alt":_vm.data.Image1.metadata
              ? _vm.data.Image1.metadata[0].data
              : _vm.data.Image1.filename}}):_vm._e(),(_vm.data.Image2 && _vm.data.Image2.fullpath)?_c('v-img',{staticClass:"d-none d-md-block",attrs:{"cover":"","src":_vm.$store.state.url + _vm.data.Image2.fullpath,"alt":_vm.data.Image2.metadata
              ? _vm.data.Image2.metadata[0].data
              : _vm.data.Image2.filename}}):_vm._e(),(_vm.data.Image3 && _vm.data.Image3.fullpath)?_c('v-img',{staticClass:"d-none d-md-block",attrs:{"cover":"","src":_vm.$store.state.url + _vm.data.Image3.fullpath,"alt":_vm.data.Image3.metadata
              ? _vm.data.Image3.metadata[0].data
              : _vm.data.Image3.filename}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.data.Text)}})]),_c('v-col',[(_vm.data.Image2 && _vm.data.Image2.fullpath)?_c('v-img',{staticClass:"d-block d-md-none",attrs:{"cover":"","src":_vm.$store.state.url + _vm.data.Image2.fullpath,"alt":_vm.data.Image2.metadata
              ? _vm.data.Image2.metadata[0].data
              : _vm.data.Image2.filename}}):_vm._e(),(_vm.data.Image3 && _vm.data.Image3.fullpath)?_c('v-img',{staticClass:"d-block d-md-none",attrs:{"cover":"","src":_vm.$store.state.url + _vm.data.Image3.fullpath,"alt":_vm.data.Image3.metadata
              ? _vm.data.Image3.metadata[0].data
              : _vm.data.Image3.filename}}):_vm._e()],1)],1):(_vm.data.Layout == 'SmallImage')?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticStyle:{"margin":"0"}},[_vm._v(_vm._s(_vm.data.Headline))])]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[(_vm.data.Image1 && _vm.data.Image1.fullpath)?_c('v-img',{attrs:{"cover":"","src":_vm.$store.state.url + _vm.data.Image1.fullpath,"alt":_vm.data.Image1.metadata
              ? _vm.data.Image1.metadata[0].data
              : _vm.data.Image1.filename}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.data.Text)}})])],1):_vm._e()],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }