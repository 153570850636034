import { render, staticRenderFns } from "./form-input.vue?vue&type=template&id=a6b74564&scoped=true&"
import script from "./form-input.vue?vue&type=script&lang=js&"
export * from "./form-input.vue?vue&type=script&lang=js&"
import style0 from "./form-input.vue?vue&type=style&index=0&id=a6b74564&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6b74564",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VIcon,VTextField})
