<template>
  <section class="section-header">
    <header
      id="header"
      class="navbar navbar-expand-lg navbar-floating navbar-end navbar-light"
    >
      <div class="container px-lg-6 Layout-1">
        <nav
          class="js-mega-menu navbar-nav-wrap navbar-floating-nav"
          style="max-height: calc(100vh - 60px); overflow: auto"
        >
          <logo />

          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-default">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-list"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                />
              </svg>
            </span>
            <span class="navbar-toggler-toggled">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path
                  d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </span>
          </button>

          <!-- Collapse -->
          <div class="collapse navbar-collapse" id="navbarNavDropdown">
            <ul class="navbar-nav">
              <template v-for="(link, key) in data.Pages">
                <!--                PARENT -->
                <li
                  class="nav-item hs-has-sub"
                  v-if="link.element.children.length > 0"
                  :key="key"
                >
                  <!--                  MAIN NAVI -->
                  <router-link
                    id="companyMegaMenu"
                    class="hs-mega-menu-invoker fw-bold nav-link dropdown-toggle"
                    :to="'/' + link.element.Url"
                    role="button"
                    aria-expanded="false"
                  >
                    {{ link.element.Title }}
                  </router-link>

                  <div class="mobile-opener-parent">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-arrow-down"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                      />
                    </svg>
                  </div>

                  <div
                    class="hs-sub-menu dropdown-menu hs-sub-menu-desktop-lg animated"
                    aria-labelledby="companyMegaMenu"
                    style="
                      min-width: 14rem;
                      animation-duration: 300ms;
                      display: none;
                    "
                  >
                    <template v-for="(child, k) in link.element.children">
                      <!--                    FIRST CHILD  -->
                      <template v-if="child.children.length === 0">
                        <!--              FIRST CHILD WITH 0 KIDS -->
                        <router-link
                          :key="k"
                          class="dropdown-item"
                          :to="'/' + child.Url"
                        >
                          {{ child.Title }}
                        </router-link>
                      </template>

                      <template v-else>
                        <!--                    FIRST CHILD WITH MULTI KIDS-->
                        <div
                          class="hs-has-sub-menu nav-item mobile-first-child"
                          :key="k"
                        >
                          <router-link
                            id="authenticationMegaMenu"
                            class="hs-mega-menu-invoker dropdown-item dropdown-toggle"
                            :to="'/' + child.Url"
                            role="button"
                            aria-expanded="false"
                            >{{ child.Title }}</router-link
                          >
                          <div class="mobile-opener-child">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-arrow-down"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                              />
                            </svg>
                          </div>
                          <div
                            class="hs-sub-menu dropdown-menu hs-sub-menu-desktop-lg animated"
                            aria-labelledby="authenticationMegaMenu"
                            style="
                              min-width: 14rem;
                              animation-duration: 300ms;
                              display: none;
                            "
                          >
                            <router-link
                              v-for="(grandchild, kee) in child.children"
                              :key="kee"
                              class="dropdown-item"
                              :to="'/' + grandchild.Url"
                              >{{ grandchild.Title }}</router-link
                            >
                          </div>
                        </div>
                      </template>
                    </template>
                  </div>
                </li>

                <!--                NO CHILDREN -->
                <li class="nav-item" v-else :key="key">
                  <router-link
                    class="btn btn-primary"
                    :to="'/' + link.element.Url"
                  >
                    {{ link.element.Title }}
                  </router-link>
                </li>
              </template>
            </ul>
          </div>
          <!-- End Collapse -->
        </nav>
      </div>
    </header>
  </section>
</template>

<script>
import logo from "@/components/parts/theHeader/logo";
import detectScroll from "@/mixins/methods/detectScroll";
import clickMenu from "@/mixins/methods/clickMenu";

export default {
  computed: {
    data() {
      return this.$store.state.navi;
    },
  },
  components: {
    logo,
  },
  mixins: [detectScroll, clickMenu],
};
</script>

<style lang="scss" scoped>
.section-header {
  header {
    .nav-link,
    a {
      color: #1e2022;
      @media (min-width: 992px) and (max-width: 1200px) {
        font-size: 13px;
      }
    }
    button.navbar-toggler {
      height: 2rem;
      width: 2rem;
      padding: 0;
      span {
        width: 100%;
        height: 100%;
        svg {
          height: 100%;
          width: 100%;
        }
      }
    }
    @media (min-width: 992px) {
      .dropdown-menu.hs-sub-menu {
        margin-top: 0;
        .hs-sub-menu {
          margin-top: -52px !important;
          margin-left: 0;
          left: 100%;
        }
      }
    }
    .hs-has-sub {
      & > a {
        width: 50%;
        display: inline-block;
        &:after {
          content: none;
        }
        &:hover {
          background-color: transparent;
        }
      }
      & > .mobile-opener-parent {
        width: 50%;
        display: inline-block;
        text-align: right;
        cursor: pointer;
      }
    }
    .mobile-opener-child {
      display: none;
    }
    @media (max-width: 991px) {
      .mobile-first-child {
        background-color: white;
        border-radius: 0.3125rem;
        &:hover {
          background-color: rgba(189, 197, 209, 0.2);
        }
        & > a {
          width: 50%;
          display: inline-block;
          &:after {
            content: none;
          }
          &:hover {
            background-color: transparent;
          }
        }

        & > .mobile-opener-child {
          display: block;
          width: 50%;
          display: inline-block;
          text-align: right;
          cursor: pointer;
        }
      }
    }
  }
  .rotated {
    transform: rotate(180deg);
  }
  svg {
    transition: transform 0.33s ease;
  }
}

.navbar-scrolled {
  #navbarNavDropdown {
    margin-right: 0;
  }
}
.navbar-expand-lg.navbar-floating .navbar-floating-nav {
  margin: 0;
}
</style>
