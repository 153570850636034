<template>
  <section
    class="section-homelink"
    style="background-color: rgba(182, 209, 222, 0.25); padding: 2rem"
  >
    <v-row>
      <v-col cols="6" lg="4" xl="2">
        <v-img
          v-if="data.Image"
          :src="$store.state.url + data.Image.fullpath"
          height="110"
          contain
          position="left center"
        />
      </v-col>
      <v-col cols="12" lg="8" xl="10">
        <h4>{{ data.Title }}</h4>

        <p v-if="data.TeaserText" v-html="data.TeaserText" class="small"></p>
        <p
          v-else-if="data.teaserText"
          v-html="data.teaserText"
          class="small"
        ></p>
        <p
          v-else-if="data.Content && data.Content[0].element.Text"
          v-html="data.Content[0].element.Text"
          class="small"
        ></p>
        <a
          v-if="data.__typename == 'object_PageNews'"
          :href="'/news-single?id=' + data.id"
        >
          Mehr erfahren
        </a>
        <a v-else :href="'/' + data.Url"> Mehr erfahren </a>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  name: "homelink",
  props: { data: Object },
};
</script>
