<template>
  <div>
    <v-radio-group
      v-model="value"
      :id="data.Name"
      :rules="rules"
      :label="data.Label + [data.required ? '*' : '']"
      :row="!data.checkboxVertical"
    >
      <v-radio
        v-for="(item, index) in data.ObjectData"
        :key="index"
        :label="item.element.Name"
        :id="item.element.Val"
        :value="item.element.Val"
      >
      </v-radio>
    </v-radio-group>
    <div v-if="data.extraText" v-html="data.extraText"></div>
  </div>
</template>

<script>
import checkVisible from "@/mixins/form/checkVisible";
import checkName from "@/mixins/form/checkName";
import { FORM_RULES } from "@/components/parts/form/formValidation";

export default {
  name: "form-radio",
  props: {
    data: Object,
    dependsOn: String,
    namespace: String,
    items: String,
    index: String,
  },
  mixins: { checkVisible, checkName, FORM_RULES },
  computed: {
    rules: function () {
      const rulesSet = [];
      if (this.data.Rules) {
        this.data.Rules.forEach((rule) => {
          rulesSet.push(
            FORM_RULES[rule.element.Validation](
              rule.element.Param,
              rule.element.errorMessage
            )
          );
        });
      }
      if (this.data.required) {
        rulesSet.push(FORM_RULES["required"]());
      }
      return rulesSet;
    },
  },
  data: () => ({
    value: "",
    error: "",
    visible: true,
  }),
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-input--selection-controls__ripple {
    display: none;
    background-color: rgba(129, 152, 166, 0.25);
    height: 1rem;
    width: 1rem;
    float: right;
    left: 0;
    top: 0;
    &.primary--text {
      background-color: rgb(102, 162, 199);
      &:before {
        display: none;
        background-color: black;
        height: 0.5rem;
        width: 0.5rem;
      }
    }
  }
}
</style>
