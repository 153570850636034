var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data)?_c('Transition',{attrs:{"appear":""}},[_c('section',{staticClass:"section-form"},[(_vm.success == false)?_c('v-form',{ref:"registerForm",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"row"},[_vm._l((_vm.data.FormFields),function(field,i){return _c('div',{key:i,class:[
            'col',
            field.element.widthBase
              ? 'col-' + field.element.widthBase
              : 'col-12',
            field.element.widthSM ? 'col-sm-' + field.element.widthSM : '',
            field.element.widthMD ? 'col-md-' + field.element.widthMD : '',
            field.element.widthLG ? 'col-lg-' + field.element.widthLG : '',
            field.element.widthXL ? 'col-xl-' + field.element.widthXL : '',
            field.element.widthXXL ? 'col-xxl-' + field.element.widthXXL : '' ]},[_c('form-' + field.element.Typ,{tag:"component",attrs:{"data":field.element,"dependsOn":field.element.DependsOn,"namespace":"","index":"0","items":""}})],1)}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading,"id":"submit"},on:{"click":_vm.validate}},[_vm._v(" "+_vm._s(_vm.data.submitText)+" ")])],1)],2)]):_c('div',{domProps:{"innerHTML":_vm._s(_vm.successText)}})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }