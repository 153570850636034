<template>
  <Transition appear v-if="data">
    <section class="section-form">
      <v-form
        ref="registerForm"
        v-model="valid"
        lazy-validation
        v-if="success == false"
      >
        <div class="row">
          <div
            v-for="(field, i) in data.FormFields"
            :key="i"
            :class="[
              'col',
              field.element.widthBase
                ? 'col-' + field.element.widthBase
                : 'col-12',
              field.element.widthSM ? 'col-sm-' + field.element.widthSM : '',
              field.element.widthMD ? 'col-md-' + field.element.widthMD : '',
              field.element.widthLG ? 'col-lg-' + field.element.widthLG : '',
              field.element.widthXL ? 'col-xl-' + field.element.widthXL : '',
              field.element.widthXXL ? 'col-xxl-' + field.element.widthXXL : '',
            ]"
          >
            <component
              :data="field.element"
              :is="'form-' + field.element.Typ"
              :dependsOn="field.element.DependsOn"
              namespace=""
              index="0"
              items=""
            ></component>
          </div>
          <v-col cols="12">
            <v-btn
              class=""
              color="primary"
              :loading="loading"
              @click="validate"
              id="submit"
            >
              {{ data.submitText }}
            </v-btn>
          </v-col>
        </div>
      </v-form>

      <div v-else v-html="successText"></div>
    </section>
  </Transition>
</template>

<script>
import componentData from "@/mixins/data/component";

import formInput from "@/components/parts/form/form-input";
import formTextarea from "@/components/parts/form/form-textarea";
import formRadio from "@/components/parts/form/form-radio";

import { FORM_RULES } from "@/components/parts/form/formValidation";
import userId from "@/mixins/methods/userId";

export default {
  name: "object_Form",
  mixins: [componentData, userId],
  components: {
    formInput,
    formTextarea,
    formRadio,
  },
  data() {
    return {
      valid: false,
      rules: FORM_RULES,
      loading: false,
      success: false,
      formData: {},
      successText: "",
    };
  },
  methods: {
    filterTextVariable(text) {
      let variable = text.slice(text.search("{{") + 3, text.search("}}") - 1);
      let toReplace = "{{ " + variable + " }}";
      let newValue = this.$store.state.form[variable];

      let newText = text.replace(toReplace, newValue);

      newText.search("{{") != -1
        ? this.filterTextVariable(newText)
        : (this.successText = newText);
    },

    validate() {
      this.valid = this.$refs.registerForm.validate();
      if (this.valid == true) {
        this.buildThisForm();
        this.send();
      }
    },
    send() {
      this.loading = true;

      this.$apollo
        .mutate({
          mutation: require("@/graphql/mutations/" +
            this.data.formMutation +
            ".graphql"),
          variables: {
            parentId: parseInt(this.data.saveFolder),
            key: this.userId(),
            user: this.formData,
          },
        })
        .then(() => {
          this.success = true;
          this.valid = false;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    buildThisForm() {
      const formChilren = this.$refs.registerForm.$children;

      this.formData = {};

      formChilren.forEach((e) => {
        e.$options.propsData.data &&
          (this.formData[e.$options.propsData.data.Name] = e.value);
      });

      this.$store.state.form = this.formData;
      this.filterTextVariable(this.data.bigSuccessText);
    },
  },
};
</script>
