<template>
  <div>
    <v-textarea
      :rules="rules"
      :loading="data.Loading"
      :label="data.Label + [data.required ? '*' : '']"
      v-model="value"
      v-if="visible"
    ></v-textarea>
    <div v-if="data.extraText" v-html="data.extraText"></div>
  </div>
</template>

<script>
import checkVisible from "@/mixins/form/checkVisible";
import checkName from "@/mixins/form/checkName";
import { FORM_RULES } from "@/components/parts/form/formValidation";

export default {
  name: "form-textarea",
  props: {
    data: Object,
    dependsOn: String,
    namespace: String,
    items: String,
    index: String,
  },
  data: () => ({
    value: "",
    visible: true,
  }),
  mixins: { checkVisible, checkName, FORM_RULES },
  computed: {
    rules: function () {
      const rulesSet = [];
      if (this.data.Rules) {
        this.data.Rules.forEach((rule) => {
          rulesSet.push(
            FORM_RULES[rule.element.Validation](
              rule.element.Param,
              rule.element.errorMessage
            )
          );
        });
      }
      if (this.data.required) {
        rulesSet.push(FORM_RULES["required"]());
      }
      return rulesSet;
    },
  },
};
</script>
