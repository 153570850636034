<template>
  <section class="section-slickCarousel">
    <VueSlickCarousel v-bind="settings" v-if="data.length" :class="template">
      <component
        v-for="(item, index) in data"
        :data="item.node"
        :key="index"
        :is="template"
      />
    </VueSlickCarousel>
    <slides-default />
  </section>
</template>

<script>
import SlidesDefault from "@/components/Templates/Slides/default";
import loadListConfig from "@/mixins/query/loadListConfig";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "Slider",
  components: { SlidesDefault, VueSlickCarousel },
  mixins: [loadListConfig],
  props: {
    data: Array,
    template: String,
    config: Object,
  },
  computed: {
    settings: function () {
      const settings = {};
      if (Array.isArray(this.config.DefaultOptions)) {
        this.config.DefaultOptions.forEach(function (option) {
          if (option.Val) {
            checkValue(option);
            settings[option.Option] = option.Val;
          }
        });
      }
      if (Array.isArray(this.config.ResponsiveOptions)) {
        settings.responsive = [];
        this.config.ResponsiveOptions.forEach(function (breakpoint) {
          if (Array.isArray(breakpoint.SliderOptions)) {
            const settingBreakpoint = {
              breakpoint: parseInt(breakpoint.maxWidth),
              settings: {},
            };
            breakpoint.SliderOptions.forEach(function (option) {
              if (option.Val) {
                checkValue(option);
                settingBreakpoint.settings[option.Option] = option.Val;
              }
            });
            settings.responsive.push(settingBreakpoint);
          }
        });
      }
      return settings;
    },
  },
};

function checkValue(option) {
  if (isNumeric(option.Val)) {
    option.Val = parseInt(option.Val);
  }
  if (option.Val == "true") {
    option.Val = true;
  }
  if (option.Val == "false") {
    option.Val = false;
  }
  return option;
}

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}
</script>

<style lang="scss" scoped>
section.section-slickCarousel {
  @media (min-width: 992px) {
    height: 500px;
  }
  height: 375px;

  ::v-deep {
    .slick-slider {
      height: 100%;
      .slick-list {
        height: 100%;
        .slick-track {
          height: 100%;
          .slick-slide > div {
            height: 100%;
          }
        }
      }

      button {
        z-index: 9;
        bottom: 0;
        top: unset;
        transform: unset;
        width: 50px;
        height: 50px;
        background-color: rgba(255, 255, 255, 0.7);
        &:before {
          display: block;
          color: #444444;
          transition: color 0.33s ease;
        }
        &:hover:before {
          color: #8794a3;
        }
        &.slick-prev {
          left: unset;
          right: 51px;
          &:before {
            content: "\2794";
            transform: rotate(180deg) translateY(-4px);
          }
        }
        &.slick-next {
          left: unset;
          right: 0;
          &:before {
            content: "\2794";
            transform: translateY(-1px);
          }
        }
      }
    }
  }
}
</style>
