import Footer from "@/graphql/queries/footer.graphql";

export default {
    name: 'loadFooter',
    computed: {
        queryFooter() {
            return {
                query: Footer,
                variables: {
                    lang: this.$store.state.lang,
                },
                fetchPolicy: "no-cache",
            };
        },
    },

    created() {
        this.$apollo
            .query(this.queryFooter)
            .then((response) => {
                if (response.data.content.edges[0] || response.data.getPage) {
                    this.$store.commit('updateFooter', response.data.content.edges[0].node)
                }
            })
    },
}