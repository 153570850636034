<template>
  <div>
    <v-text-field
      validate-on-blur
      :ref="data.Name"
      :id="data.Name"
      :rules="rules"
      :loading="data.Loading"
      :label="data.Label + [data.required ? '*' : '']"
      :required="[data.required ? true : false]"
      v-model="value"
      v-if="visible"
    >
      <template v-slot:append>
        <v-icon v-if="data.appendIconEuro">mdi-currency-eur</v-icon>
        <v-icon color="green">mdi-check</v-icon>
      </template>
    </v-text-field>
    <div v-if="data.extraText" v-html="data.extraText"></div>
  </div>
</template>

<script>
import checkVisible from "@/mixins/form/checkVisible";
import checkName from "@/mixins/form/checkName";
import { FORM_RULES } from "@/components/parts/form/formValidation";

export default {
  name: "form-input",
  props: {
    data: Object,
    dependsOn: String,
    namespace: String,
    items: String,
    index: String,
    isItValid: String,
  },
  mixins: { checkVisible, checkName, FORM_RULES },
  computed: {
    rules: function () {
      const rulesSet = [];
      if (this.data.Rules) {
        this.data.Rules.forEach((rule) => {
          rulesSet.push(
            FORM_RULES[rule.element.Validation](
              rule.element.Param,
              rule.element.errorMessage
            )
          );
        });
      }
      if (this.data.required) {
        rulesSet.push(FORM_RULES["required"]());
      }
      return rulesSet;
    },
  },
  data: () => ({
    value: "",
    visible: true,
  }),
  methods: {
    updateValue() {
      this.$store.commit("updateField", [
        this.data.Name,
        this.value,
        this.namespace,
        this.items,
        this.index,
      ]);
    },
  },
};
</script>

<style scoped lang="scss">
.v-input {
  ::v-deep .v-input__append-inner .green--text {
    transition: opacity 0.33s ease;
    opacity: 0;
  }
  &.error--text {
    ::v-deep .v-input__append-inner .green--text {
      opacity: 0 !important;
    }
  }
  &.v-input--is-label-active:not(.v-input--is-focused) {
    ::v-deep .v-input__append-inner .green--text {
      opacity: 1;
    }
  }
}
</style>
