import Vue from 'vue'
import VueI18n from 'vue-i18n'
// import axios from "axios";
Vue.use(VueI18n)

function loadLocaleMessages () {
    // const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    // locales.keys().forEach(key => {
    //     const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    //     if (matched && matched.length > 1) {
    //         const locale = matched[1]
    //         messages[locale] = locales(key)
    //     }
    // })
    // axios.get('https://sli-gmbh.fr-1.paas.massivegrid.net/locales/de.json').
    // then(response => {
    //     messages['de'] = response.data
    // })
    // axios.get('https://sli-gmbh.fr-1.paas.massivegrid.net/locales/en.json').
    // then(response => {
    //     messages['en'] = response.data
    // })
    return messages
}

export default new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'de',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'de',
    messages: loadLocaleMessages()
})
