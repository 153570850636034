<template>
  <section class="section-page">
    <div
      class="container vh-top-gap bg-secondary"
      v-if="query.result.topGap"
    ></div>
    <template v-if="query.result.length == 0">
      <v-progress-linear
        indeterminate
        fixed
        height="6"
        background-color="transparent"
        color="#1976d2"
        style="z-index: 999"
      ></v-progress-linear>
    </template>

    <div class="container bg-secondary px-lg-6" style="padding-bottom: 4rem">
      <skeleton :condition="query.result.length == 0" />

      <template v-for="(contentElement, key) in query.result.Content">
        <component
          :key="key"
          :number="key"
          :data="contentElement.element"
          :is="contentElement.element.__typename"
          :class="[
            contentElement.metadata[0].value
              ? 'spacing-bottom-' + contentElement.metadata[0].value
              : '',
            contentElement.metadata[1].value
              ? 'spacing-top-' + contentElement.metadata[1].value
              : '',
          ]"
        />
      </template>
    </div>
  </section>
</template>

<script>
import loadPage from "@/mixins/query/loadPage";
import skeleton from "@/components/parts/loading/skeleton";

import object_PageHeader from "@/components/objects/object_PageHeader";
import object_PageTeaser from "@/components/objects/object_PageTeaser";
import object_PageContent from "@/components/objects/object_PageContents";
import object_ListConfig from "@/components/objects/object_ListConfig";
import object_Employees from "@/components/objects/object_Employees";
import object_Form from "@/components/objects/object_Form";
import object_PageNewsPicker from "@/components/objects/object_PageNewsPicker";
import object_PageNews from "@/components/objects/object_PageNews";
import object_Page from "@/components/objects/object_Page";

export default {
  name: "Page",
  mixins: [loadPage],
  components: {
    skeleton,
    object_PageHeader,
    object_PageContent,
    object_ListConfig,
    object_PageTeaser,
    object_Employees,
    object_Form,
    object_PageNewsPicker,
    object_PageNews,
    object_Page,
  },
  methods: {
    removeTopGap() {
      document.querySelector();
    },
  },
};
</script>

<style lang="scss">
.spacing-top-XXL {
  @media (min-width: 1201px) {
    padding-top: 7rem;
  }
  @media (max-width: 1200px) {
    padding-top: 6rem;
  }
  @media (max-width: 992px) {
    padding-top: 5rem;
  }
  @media (max-width: 768px) {
    padding-top: 4rem;
  }
  @media (max-width: 576px) {
    padding-top: 3rem;
  }
}
.spacing-top-XL {
  @media (min-width: 1201px) {
    padding-top: 6rem;
  }
  @media (max-width: 1200px) {
    padding-top: 5rem;
  }
  @media (max-width: 992px) {
    padding-top: 4rem;
  }
  @media (max-width: 768px) {
    padding-top: 3rem;
  }
  @media (max-width: 576px) {
    padding-top: 2rem;
  }
}
.spacing-top-L {
  @media (min-width: 1201px) {
    padding-top: 5rem;
  }
  @media (max-width: 1200px) {
    padding-top: 4rem;
  }
  @media (max-width: 992px) {
    padding-top: 3rem;
  }
  @media (max-width: 768px) {
    padding-top: 2rem;
  }
  @media (max-width: 576px) {
    padding-top: 1.5rem;
  }
}
.spacing-top-M {
  @media (min-width: 1201px) {
    padding-top: 4rem;
  }
  @media (max-width: 1200px) {
    padding-top: 3rem;
  }
  @media (max-width: 992px) {
    padding-top: 2rem;
  }
  @media (max-width: 768px) {
    padding-top: 1rem;
  }
  @media (max-width: 576px) {
    padding-top: 1rem;
  }
}
.spacing-top-S {
  @media (min-width: 1201px) {
    padding-top: 3rem;
  }
  @media (max-width: 1200px) {
    padding-top: 2rem;
  }
  @media (max-width: 992px) {
    padding-top: 1.5rem;
  }
  @media (max-width: 768px) {
    padding-top: 1rem;
  }
  @media (max-width: 576px) {
    padding-top: 0.5rem;
  }
}
.spacing-top-XS {
  @media (min-width: 1201px) {
    padding-top: 2rem;
  }
  @media (max-width: 1200px) {
    padding-top: 1.5rem;
  }
  @media (max-width: 992px) {
    padding-top: 1rem;
  }
  @media (max-width: 768px) {
    padding-top: 0.5rem;
  }
  @media (max-width: 576px) {
    padding-top: 0.25rem;
  }
}

.spacing-bottom-XXL {
  @media (min-width: 1201px) {
    padding-bottom: 6rem;
  }
  @media (max-width: 1200px) {
    padding-bottom: 5rem;
  }
  @media (max-width: 992px) {
    padding-bottom: 4rem;
  }
  @media (max-width: 768px) {
    padding-bottom: 3rem;
  }
  @media (max-width: 576px) {
    padding-bottom: 2rem;
  }
}
.spacing-bottom-XL {
  @media (min-width: 1201px) {
    padding-bottom: 5rem;
  }
  @media (max-width: 1200px) {
    padding-bottom: 4rem;
  }
  @media (max-width: 992px) {
    padding-bottom: 3rem;
  }
  @media (max-width: 768px) {
    padding-bottom: 2rem;
  }
  @media (max-width: 576px) {
    padding-bottom: 2rem;
  }
}
.spacing-bottom-L {
  @media (min-width: 1201px) {
    padding-bottom: 4rem;
  }
  @media (max-width: 1200px) {
    padding-bottom: 3.5rem;
  }
  @media (max-width: 992px) {
    padding-bottom: 3rem;
  }
  @media (max-width: 768px) {
    padding-bottom: 2.5rem;
  }
  @media (max-width: 576px) {
    padding-bottom: 2rem;
  }
}
.spacing-bottom-M {
  @media (min-width: 1201px) {
    padding-bottom: 3rem;
  }
  @media (max-width: 1200px) {
    padding-bottom: 2.5rem;
  }
  @media (max-width: 992px) {
    padding-bottom: 2rem;
  }
  @media (max-width: 768px) {
    padding-bottom: 2rem;
  }
  @media (max-width: 576px) {
    padding-bottom: 1.5rem;
  }
}
.spacing-bottom-S {
  @media (min-width: 1201px) {
    padding-bottom: 2rem;
  }
  @media (max-width: 1200px) {
    padding-bottom: 2rem;
  }
  @media (max-width: 992px) {
    padding-bottom: 1.5rem;
  }
  @media (max-width: 768px) {
    padding-bottom: 1.5rem;
  }
  @media (max-width: 576px) {
    padding-bottom: 1rem;
  }
}
.spacing-bottom-XS {
  @media (min-width: 1201px) {
    padding-bottom: 1rem;
  }
  @media (max-width: 1200px) {
    padding-bottom: 1rem;
  }
  @media (max-width: 992px) {
    padding-bottom: 1rem;
  }
  @media (max-width: 768px) {
    padding-bottom: 1rem;
  }
  @media (max-width: 576px) {
    padding-bottom: 1rem;
  }
}
</style>
