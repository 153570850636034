// import Metadata from "@/graphql/queries/metadata.graphql";

export default {
  name: "loadMetadata",
  // data() {
  //   return {
  //     metadata: {
  //       title: "loading",
  //       author: "loading",
  //       description: "loading",
  //       keywords: "loading",
  //     },
  //   };
  // },
  // computed: {
  //   queryMetadata() {
  //     return {
  //       query: Metadata,
  //       variables: {
  //         lang: this.$store.state.lang,
  //       },
  //       fetchPolicy: "no-cache",
  //     };
  //   },
  // },

  // methods: {
  //   querying() {
  //     this.$apollo.query(this.queryMetadata).then((response) => {
  //       this.metadata.title = response.data.content.edges[0].node.Title;
  //       this.metadata.author = response.data.content.edges[0].node.Author;
  //       this.metadata.description =
  //         response.data.content.edges[0].node.description;
  //       this.metadata.keywords = response.data.content.edges[0].node.keywords;
  //       this.metadata.image = response.data.content.edges[0].node.image;
  //
  //       this.$emit("updateHead");
  //     });
  //   },
  // },

  created() {
    // this.querying();
  },

  head: {
    title: function () {
      return {
        inner: "SLI GmbH",
        separator: " ",
      };
    },
    meta: function () {
      return [
        // BASE
        { hid: "name", i: "name", c: "SLI GmbH" },
        { hid: "description", n: "description", c: "Das ist die SLI GmbH" },
        { n: "author", c: "SLI GmbH" },
        { n: "keywords", c: "SLI GmbH" },
        {
          n: "viewport",
          c: "width=device-width, initial-scale=1, shrink-to-fit=no",
        },
        // { n: "viewport", c: this.metadata.viewport },

        // TWITTER
        // { p: "twitter:card", c: this.metadata.title },
        // { p: "twitter:site", c: this.metadata.title },
        // { p: "twitter:creator", c: this.metadata.title },
        // { p: "twitter:url", c: this.metadata.title },
        // { p: "twitter:title", c: this.metadata.title },
        // { p: "twitter:description", c: this.metadata.title },
        // { p: "twitter:image", c: this.metadata.title },
        // { p: "twitter:image:alt", c: this.metadata.title },

        // FACEBOOK
        // { p: "og:url", c: this.metadata.title },
        // { p: "og:title", c: this.metadata.title },
        // { p: "og:type", c: this.metadata.title },
        // { p: "og:image", c: this.metadata.title },
        // { p: "og:image:alt", c: this.metadata.title },
        // { p: "og:description", c: this.metadata.title },
        // { p: "og:site_name", c: this.metadata.title },
        // { p: "og:locale", c: this.metadata.title },
      ];
    },
    link: function () {
      return [
        // FAVICON
        {
          r: "icon",
          h: this.$store.state.url + "favicon/favicon.ico",
          t: "image/png",
        },
        {
          r: "icon",
          h: this.$store.state.url + "favicon/favicon-16x16.png",
          sz: "16x16",
          t: "image/png",
        },
        {
          r: "icon",
          h: this.$store.state.url + "favicon/favicon-32x32.png",
          sz: "32x32",
          t: "image/png",
        },
        {
          r: "icon",
          h: this.$store.state.url + "favicon/favicon-192x192.png",
          sz: "192x192",
          t: "image/png",
        },

        {
          r: "icon",
          h: this.$store.state.url + "favicon/favicon-512x512.png",
          sz: "512x512",
          t: "image/png",
        },
        {
          r: "mask-icon",
          h: this.$store.state.url + "favicon/logo.jpg",
          t: "image/png",
          color: "white",
        },
        {
          r: "apple-touch-icon",
          h: this.$store.state.url + "favicon/apple-touch-icon.png",
          t: "image/png",
          color: "white",
        },
      ];

      // AUTHOR
      // {
      //   r: "me",
      //   h: "mailto:name@example.com",
      // },
      // {
      //   r: "me",
      //   h: "sms:+000000000",
      // },
      // {
      //   r: "me",
      //   h: "tel:+000000000",
      // },
      // {
      //   r: "author",
      //   h: "Informationstext",
      // },
      // ];
    },
  },
};
