/**
 * GLOBAL FORM RULE CONFIGS
 */
export const FORM_RULES = {
  // Required | Not empty
  required: function (message) {
    return (v) => !!v || (message ? message : "Dieses Feld ist notwendig.");
  },

  // Number
  number: function (para, message) {
    return (v) => {
      if (v)
        return (
          /^\d+$/.test(v) ||
          (message ? message : "Dieses Feld benötigt eine Zahl.")
        );
      return true;
    };
  },

  // minValue
  minValue: function (para, message) {
    return (v) => {
      if (v)
        return (
          parseInt(v) >= parseInt(para) ||
          (message
            ? message
            : "Dieses Feld benötigt einen Wert von mindestens " + para + ".")
        );
      return true;
    };
  },

  // MaxValue
  maxValue: function (para, message) {
    return (v) => {
      if (v)
        return (
          parseInt(v) <= parseInt(para) ||
          (message
            ? message
            : "Dieses Feld benötigt einen Wert von maximal " + para + ".")
        );
      return true;
    };
  },

  // MinItems
  minItems: function (para, message) {
    return (v) => {
      if (v)
        return (
          (v && v.length >= para) ||
          (message
            ? message
            : "Dieses Feld benötigt mindestens " + para + "Zeichen.")
        );
      return true;
    };
  },

  // MaxItems
  maxItems: function (para, message) {
    return (v) => {
      if (v)
        return (
          (v && v.length <= para) ||
          (message
            ? message
            : "Dieses Feld benötigt maximal " + para + "Zeichen.")
        );
      return true;
    };
  },

  // Type
  typeface: function (para, message) {
    return (v) => {
      if (v)
        return (
          !/[^a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,./'-]/.test(
            v
          ) || (message ? message : "Dieses Feld benötigt reale Zeichen.")
        );
      return true;
    };
  },

  // Email
  email: function (para, message) {
    return (v) => {
      if (v)
        return (
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) ||
          (message
            ? message
            : "Dieses Feld benötigt eine gültige E-Mail-Adresse.")
        );
      return true;
    };
  },

  // Zipcode
  zipcode: function (para, message) {
    return (v) => {
      if (v)
        return (
          /^[0-9]{4,5}$/.test(v) ||
          (message
            ? message
            : "Dieses Feld benötigt eine gültige Postleitzahl.")
        );
      return true;
    };
  },

  // Phone
  telephone: function (para, message) {
    return (v) => {
      if (v)
        return (
          /^[\d /.()+-]+$/.test(v) ||
          (message
            ? message
            : "Dieses Feld benötigt eine gültige Telefonnummer.")
        );
      return true;
    };
  },

  // Year
  year: function (para, message) {
    return (v) => {
      if (v)
        return (
          /^(19|20)\d{2}$/.test(v) ||
          (message ? message : "Dieses Feld benötigt ein gültiges Jahr.")
        );
      return true;
    };
  },

  // Birthdate
  birthday: function (para, message) {
    return (v) => {
      if (v)
        return (
          !!v ||
          (message
            ? message
            : "Dieses Jahr benötigt einen gültigen Geburtstag.")
        );
      return true;
    };
  },
};
