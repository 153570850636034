<template>
  <Transition appear v-if="data">
    <section class="section-pageTeaser">
      <div class="container bg-secondary">
        <v-row>
          <v-col cols="12">
            <h3 v-if="data.Headline" style="margin: 0">
              {{ data.Headline }}
            </h3>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-img
              v-if="data.Image1"
              :src="$store.state.url + data.Image1.fullpath"
              :alt="
                data.Image1.metadata
                  ? data.Image1.metadata[0].data
                  : data.Image1.filename
              "
          /></v-col>
          <v-col cols="12" md="6" lg="8">
            <span v-html="data.Text"></span>
          </v-col>
        </v-row>
      </div>
    </section>
  </Transition>
</template>

<script>
import componentData from "@/mixins/data/component";

export default {
  name: "object_PageTeaser",
  mixins: [componentData],
  data() {
    return {
      show: false,
    };
  },
};
</script>

<style lang="scss" scoped>
section.section-pageTeaser {
  .Layout-1 {
    @media (min-width: 992px) {
      span {
        ::v-deep p {
          columns: 2;
        }
      }
    }
  }
  .Layout-2 {
  }
  .Layout-3 {
  }
  .Layout-4 {
  }
  .Layout-5 {
  }
}
</style>
