<template>
  <section class="section-slidesDefault" v-if="data">
    <v-img
      v-if="data.backgroundimage && data.backgroundimage.fullpath"
      :src="$store.state.url + data.backgroundimage.fullpath"
    >
      <div
        class="container d-flex align-items-end vh-swipe-sli content-space-t-3 content-space-b-1 ps-md-6"
      >
        <div style="z-index: 999">
          <h3 class="mb-1" v-if="data.new">Neu:</h3>
          <h1 class="display-6 mb-0" v-html="data.headline"></h1>
        </div>
      </div>
      <div class="header-gradient"></div>
    </v-img>
  </section>
</template>

<script>
import componentData from "@/mixins/data/component";

export default {
  name: "SlidesDefault",
  mixins: [componentData],
};
</script>

<style lang="scss" scoped>
section.section-slidesDefault {
  height: 100%;
  .v-image {
    height: 100%;
  }
  .header-gradient {
    position: absolute;
    inset: 0;
    z-index: 998;
    background: linear-gradient(transparent, rgba(220, 240, 249, 1));
  }
}
</style>
